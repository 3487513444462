<template>
    <h3>SA Twenty Questions</h3>
    <hr>
    <p>We in SA find we are able to indentify with some, and in some cases all of these questions:</p>
    <ol>
        <li>Have you ever thought you needed help for your sexual thinking or behavior?</li>
        <li>That you'd be better off if you didn't keep “giving in”?</li>
        <li>That sex or stimuli are controlling you?</li>
        <li>Have you ever tried to stop or limit doing what you felt was wrong in your sexual behavior?</li>
        <li>Do you resort to sex to escape, relieve anxiety, or because you can't cope?</li>
        <li>Do you feel guilt, remorse or depression afterward?</li>
        <li>Has your pursuit of sex become more compulsive?</li>
        <li>Does it interfere with relations with your spouse?</li>
        <li>Do you have to resort to images or memories during sex?</li>
        <li>Does an irresistible impulse arise when the other party makes the overtures or sex is offered?</li>
        <li>Do you keep going from one “relationship” or lover to another?</li>
        <li>Do you feel the “right relationship” would help you stop lusting, masturbating, or being so promiscuous?</li>
        <li>Do you have a destructive need—a desperate sexual or emotional need for someone?</li>
        <li>Does pursuit of sex make you careless for yourself or the welfare of your family or others?</li>
        <li>Has your effectiveness or concentration decreased as sex has become more compulsive?</li>
        <li>Do you lose time from work for it?</li>
        <li>Do you turn to a lower environment when pursuing sex?</li>
        <li>Do you want to get away from the sex partner as soon as possible after the act?</li>
        <li>Although your spouse is sexually compatible, do you still masturbate or have sex with others?</li>
        <li>Have you ever been arrested for a sex-related offense?</li>
        <p><router-link to="/newcomers" role="button">&larr; Back</router-link></p>
    </ol>
</template>

<style scoped>
h3{
    text-align: left;
}

li, p {
text-align: left;
}

</style>