<template>
  <h1>Capital Area Sexaholics Anonymous (CASA)</h1>
  <hr>
  <p>Capital Area Sexaholics Anonymous (CASA) serves the SA meetings in Washington, DC and nearby counties in Virginia and Maryland.</p>
  <p>Sexaholics Anonymous is a 12-step fellowship with a solution to the problems of lust, sex, and pornography addiction.</p>
<h2>About Sexaholics Anonymous</h2>
<p>Sexaholics Anonymous is a fellowship of men and women who share their experience, strength, and hope with each other that they may solve their common problem and help others to recover. The only requirement for membership is a desire to stop lusting and become sexually sober. There are no dues or fees for SA membership; we are self-supporting through our own contributions. SA is not allied with any sect, denomination, politics, organization, or institution; does not wish to engage in any controversy; neither endorses nor opposes any causes. Our primary purpose is to stay sexually sober and help others to achieve sexual sobriety.</p>
<p>We have a solution. We don't claim it's for everybody, but for us, it works. If you identify with us and think you may share our problem, we'd like to share our solution with you. In defining sobriety, we do not speak for those outside Sexaholics Anonymous. We can only speak for ourselves. Thus, for the married sexaholic, sexual sobriety means having no form of sex with self or with persons other than the spouse. In SA's sobriety definition, the term “spouse” refers to one's partner in a marriage between a man and a woman. For the unmarried sexaholic, sexual sobriety means freedom from sex of any kind. And for all of us, single and married alike, sexual sobriety also includes progressive victory over lust.</p>
  
  <h2>Contact Us</h2>
  <p>Email us at <a href="mailto:ContactCASA12@gmail.com">ContactCASA12@gmail.com</a> or call 703-866-6929</p>

  <p>Please leave your first name and phone number if you would like us to call!</p>

</template>

<style scoped>
p {
text-align: left;
}

</style>
