<template>
<h1>Contribute</h1>
<hr>
<br>
Our Seventh Tradition states that we are self-supporting through our own contributions.
<br>
You can make a contribution to CASA via Venmo:<br> 
<br>
<b>@Intergroup-CASA</b><br>
<br>
or by mail:<br>
<br>
<b>CASA Intergroup<br>
P.O. Box 77461<br>
Washington DC 20013-7461</b><br>
<br>
</template>
