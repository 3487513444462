<template>
<div class="full">
  <h1>Meeting List</h1>
  <div class="red">
  <b>NEWCOMERS</b>, please email us at <a href="mailto:ContactCASA12@gmail.com">ContactCASA12@gmail.com</a> or call 703-866-6929.<br>
  Please leave your first name and phone number if you would like us to call!<br> 
  </div>
  <br>
  <p><b>MEMBERS</b> feel free to use the list below or email us for a more complete list.</p>
  <iframe src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSAWIT3Ykq1KpqPqbmzmaaCkBsQ7hTsz6b1VEkkZOvh4U3LLKZWJm37F1tTHa_smj12KXdxwIkvEb_o/pubhtml?gid=1661762021&amp;single=true&amp;widget=true&amp;headers=false"></iframe>
</div>
</template>

<style scoped>
iframe {
  display: block;
  width: 100%;
  flex-grow: 1;
}

.full {
  display:flex;
  height: 90vh;
  flex-direction: column;
}

.red {
  border-style: solid;
  border-color: red;
  text-align: left;
}

p {
  padding: 0;
  margin: 0;
  text-align: left;
}


</style>